<template>
  <color-button question-banner-back-button type="dark" @click="$emit('click')">
    <img
      class="coach-user-questions--content-btn-icon"
      src="/img/icon/icon-arrow-down-white.svg"
      alt="go back"
    />
  </color-button>
</template>
<script>
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  components: { ColorButton },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[question-banner-back-button] {.wh(40, 40);.flex;.flex-ai(center);.flex-jc(center);
  //position: absolute;
  //top: -59px;
  //left: 0;
  //width: 40px;
  //height: 40px;
  //border-radius: 12px;
  //font-size: 0;
  //background: rgba(0, 0, 0, 0.6);
  //border-radius: 12px;
  > img {transform: rotate(90deg);}
}
</style>
