<template>
  <div coach-questions data-cy="coach-question-view">
    <div class="banner-wrap">
      <div class="banner-inner">
        <question-banner-a @back="onBack" @post="postQuestion" />
        <div class="right-side-wrap">
          <DiscordButton />
          <CoachList />
        </div>
      </div>
    </div>
    <div class="nav-area">
      <nav-tab v-model="questionType" :items="navItems" />
    </div>
    <div class="content-area">
      <div class="content-area-inner">
        <div class="filters">
          <button-filter :class="{active: !hasLane && !hasChamp}" @click="resetFilter">전체</button-filter>
          <button-filter v-show="!hasLane" @click="setLane">
            <span>포지션</span>
          </button-filter>
          <button-filter v-show="hasLane" class="active" @click="setLane">
            <span>포지션 :</span>
            <em>{{ lolLaneText }}</em>
          </button-filter>
          <button-filter v-show="!hasChamp" @click="setChamp">
            <span>챔피언</span>
          </button-filter>
          <button-filter v-show="hasChamp" class="active" @click="setChamp">
            <span>챔피언 :</span>
            <lol-champ-data tag="em" :champ-code="lolChamp" v-slot="{ champText }">{{ champText }}</lol-champ-data>
          </button-filter>
        </div>
        <template v-if="NoQuestion">
          <no-content v-if="questionType === 'waiting'" :text="$t('_.MC_NO_DATA_LIST_NOW_WAITING')" alt="memopad" src="/img/coaching/memopad.svg" />
          <no-content v-if="questionType === 'answered'" :text="$t('_.MC_NO_DATA_LIST_NOW_DONE')" alt="memopad" src="/img/coaching/memopad.svg" />
          <no-content v-if="questionType === 'my-question'" :text="$t('_.MC_NO_DATA_MYMENU_QUESTION')" alt="memopad" src="/img/coaching/memopad.svg" />
          <no-content v-if="questionType === 'scrap'" :text="$t('_.MC_NO_DATA_LIST_SCRAP')" alt="eyes" src="/img/coaching/seeking-eyes.svg" />
        </template>
        <question-preview v-for="q in questions" :question="q" :key="q.question.boardWriteId" clickable @examine="openQuestion" />
        <area-more v-if="hasMore" @click="getMore" />
      </div>
    </div>
  </div>
</template>

<script>
import cursorFetcher from '@shared/mixins/cursorFetcher';
import { getter } from '@shared/utils/storeUtils';
import NavTab from '@/views/components/coaching/question/NavTab.vue';
import ButtonFilter from '@/views/components/coaching/question/ButtonFilter.vue';
import QuestionPreview from '@/views/components/coaching/question/QuestionPreview.vue';
import AreaMore from '@/views/components/coaching/question/AreaMore.vue';
import QuestionBannerA from '@/views/components/coaching/question/QuestionBannerA.vue';
import questionsFilter from '@/mixins/questionsFilter';
import CoachList from '@/views/components/coaching/coachList/CoachList.vue';
import DiscordButton from '@/views/components/coaching/DiscordButton.vue';
import NoContent from '@/views/components/coaching/question-detail/NoContent.vue';
import coach from '@/mixins/coach';
import { LOL_POSITION_TEXT } from '@/constants/lol';
import LolChampData from '@/views/components/coaching/LolChampData.vue';

export default {
  name: 'CoachQuestions',
  components: { LolChampData, NoContent, DiscordButton, CoachList, QuestionBannerA, AreaMore, QuestionPreview, ButtonFilter, NavTab },
  lexicon: 'coaching',
  data: () => ({
    gameInfo: null,
    navItems: [
      { text: '답변 완료', value: 'answered' },
      { text: '답변 대기', value: 'waiting' },
      { text: '내 스크랩', value: 'scrap' },
      { text: '내 질문', value: 'my-question' },
    ],
    questionInfo: /** @type{PagedQuestions} */ null,
  }),
  mixins: [coach, questionsFilter, cursorFetcher({
    questionInfo: ({ cursor, route, services, questionType, lolLane, lolChamp }) => {
      const { gameId } = route.params;
      const opt = { cursor };
      const category = [];
      if (lolLane && lolLane !== '') {
        category.push({ groupName: 'LOL_LANE', values: [lolLane] });
      }
      if (lolChamp && lolChamp !== '') {
        category.push({ groupName: 'LOL_CHAMPION', values: [lolChamp] });
      }
      if (category.length >= 1) opt.category = category;
      switch (questionType) {
        case 'waiting':
          opt.q = 'isAnswered eq false';
          break;
        case 'my-question':
          opt.q = 'isMyQuestion eq true';
          break;
        case 'scrap':
          opt.q = 'isScrap eq true';
          break;
        default:
          opt.q = 'isAnswered eq true';
      }
      opt.orderBy = 'latestAnswerDatetime';
      opt.order = 'desc';
      return services.coaching.getQuestions({ size: 5, gameId, ...opt });
    },
  })],
  metaInfo() {
    return {
      title: this.$t('meta.coachQuestion.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachQuestion.title') },
        { vmid: 'description', content: this.$t('meta.coachQuestion.description') },
        { vmid: 'url', content: this.$t('meta.coachQuestion.url') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
        { vmid: 'keywords', content: this.$t('meta.coachFinder.keywords') },
      ],
    };
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    questionType: {
      get() {
        return this?.$route?.query?.['question-type'] || 'answered';
      },
      set(questionType) {
        if (questionType === 'scrap' || questionType === 'my-question') {
          if (!this.isLogin) {
            this.$services.auth.oAuthLogin(true);
            return;
          }
        }
        const query = this.getMergedQuery({ 'question-type': questionType });
        this.$router.push({ query });
      },
    },
    questions() {
      return /** @type{PreviewQuestion[]} */ this.questionInfo?.items || [];
    },
    NoQuestion() {
      return this.questionInfo?.totalCount === 0;
    },
    hasMore() {
      return this.questionInfo?.hasNext;
    },
    lolLaneText() {
      return this.$t(`coaching.${LOL_POSITION_TEXT[this.lolLane]}`);
    },
  },
  methods: {
    getMore() {
      return this.fetchMore(this.questionInfo);
    },
    onBack() {
      this.$router.push({ name: 'CoachHome' });
    },
    async resetData() {
      this.gameInfo = { id: this.$route.params.gameId.toLowerCase(), ipList: this.$store.state.info.games };
      const lolLane = this.$route.query?.['lol-lane'] || '';
      const lolChamp = this.$route.query?.['lol-champ'] || '';
      const questionType = this.$route.query?.['question-type'] || 'answered';
      await this.fetchInitAll({ lolLane, lolChamp, questionType });
    },
  },
  watch: {
    $route: 'resetData',
  },
  async mounted() {
    await this.resetData();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-questions] {
  > .banner-wrap {.bgc(#5555e2);}
  > .banner-wrap > .banner-inner { .rel; .max-w(1280); }
  > .banner-wrap > .banner-inner > .right-side-wrap { .abs; .hide;}
  [question-preview] + [question-preview] { .mt(8); }
  > .content-area {.bgc(#ebebf0);.pb(30)}
  > .content-area > .content-area-inner > .filters {.pt(20);.pb(20);
    [button-filter] + [button-filter] {.ml(8)}
  }
  > .content-area > .content-area-inner {.max-w(1280);.p(0, 58);.m(auto);}
  > .nav-area {border-bottom: solid 1px #d8d8d8;.bgc(#fff);
    [nav-tab] {.bgc(#fff);.max-w(1280);.m(0, auto);.p(0, 58)}
  }
  [area-more] {.mt(20)}

  @media (@tp-down) {
    > .content-area > .content-area-inner {.p(0, 28);}
    > .nav-area {.p(0, 20);
      [nav-tab] {.p(0)}
    }
  }

  @media (@ds-up) {
    > .banner-wrap > .banner-inner {.m(0, auto); .pr(432);}
    > .banner-wrap > .banner-inner > .right-side-wrap {display: unset; .ml(auto); .t(92); .r(0);}
    > .content-area {.pr(432); .min-h(800);}
    > .content-area > .content-area-inner { .max-w(848); .pr(0);}
  }
}

</style>
