<template>
  <div nav-tab>
    <button
      v-for="(item, index) in items"
      :key="index + item.value"
      @click="itemClickHandler(item.value)"
      :class="{ active: item.value === value }"
      class="menu-item"
    >
      <h3 class="menu-item__text">{{ item.text }}</h3>
      <div class="under-line" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      // { value, text }
      default: () => [],
    },
    value: {
      default: null,
    },
  },
  methods: {
    itemClickHandler(value) {
      this.$emit('input', value);
      this.$emit('item-click', value);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[nav-tab] { .flex;

  .menu-item { .rel;.min-w(112);.h(68);.pointer;.bgc(unset);border: unset;

    .menu-item__text {.c(#191919);.fs(16);font-weight: 500;.p(18, 24, 18, 24);}
    .under-line {.abs;.b(0);.wh(100%, 4);.bgc(transparent);}

    &.active {
      .menu-item__text {.c(#8300ff);.bold;}
      .under-line {.block;.bgc(#8300ff);}
    }
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    .menu-item {.min-w(83);.h(48);

      .menu-item__text {.fs(14); .p(0);}
    }
  }
}
</style>
