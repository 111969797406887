<template>
  <div question-banner>
    <div class="title-wrap">
      <div class="sub-wrap">
        <h3 class="sub-title">{{ $t('_.MS_MAIN_LVUPNOW') }}</h3>
        <!--        <img src="img/Question.svg" class="question-mark" />-->
        <svg-question class="question-mark" />
      </div>
      <h1 class="title">
        {{ $t('_.MT_MAIN_LVUPNOW') }}
      </h1>
    </div>
    <div class="btn-wrap">
      <button class="btn-ask" @click="$emit('click')">
        {{ $t('_.BT_writecard_question') }}
      </button>
    </div>
  </div>
</template>

<script>
import SvgQuestion from '@/views/graphics/svg-question.vue';

export default {
  components: { SvgQuestion },
  lexicon: 'coaching',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[question-banner] {
  .title-wrap { .flex;.flex-jc(center);.flex-ai(center);.flex-column;
    .sub-wrap { .rel;
      .sub-title { .c(#fff);.fs(24);.lh(36);font-style: normal;font-weight: normal;}
      .question-mark { .abs;.wh(70, 70);.t(-10);.r(-74);.o(.5);
        * { fill: #fff;}
      }
    }

    .title { .c(#fff);.fs(42);.bold;.lh(62);}
  }
  .btn-wrap { .mt(20);text-align: center;

    .btn-ask { .wh(280, 60);.bgc(#f19638);.c(#fff);.br(50);.fs(20);.lh(16);.pointer;
      border: none;text-align: center;font-style: normal;font-weight: bold;line-height: 16px;
    }
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    .title-wrap {
      .sub-wrap {
        .sub-title {.fs(14);.lh(21);}
        .question-mark {display: none;}
      }
      .title {.fs(22);.lh(34);}
    }
    .btn-wrap {
      .btn-ask {.wh(159, 45);.fs(14);.lh(21);}
    }
  }
}
</style>
