<template>
  <PrimaryButton color="blue" discord-button @click.native="discordBtnHandler">
    <div class="discord-button-inner">
      <img class="discord-icon" src="/img/icon/platform/icon-discord.svg" alt="icon-discord" />
      <span class="discord-text">{{ $t('coaching.BA_discord_install1') }}</span>
      <div class="discord-badge">
        <span>{{ $t('coaching.BA_discord_install2') }}</span>
      </div>
    </div>
  </PrimaryButton>
</template>

<script>
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';

export default {
  name: 'DiscordButton',
  components: { PrimaryButton },
  methods: {
    discordBtnHandler() {
      const url = 'https://discord.com/download';
      window.open(url);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[discord-button] { .w(100%); .h(48); .br(12); .p(0);
  .discord-button-inner { .h(48); .flex; .items-center; .justify-center;
    .discord-icon { .w(40); .h(40); .z(1); }
    .discord-text { .fs(16); font-weight: 500; .lh(16); .c(#fff); }
    .discord-badge { border: 2px solid #fff; .br(12); .fs(11); .lh(16); .c(fff); .p(2, 8); .ml(12);}
  }
}
</style>
