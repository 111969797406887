<template>
  <div question-banner-a>
    <div class="top-content-wrap">
      <question-banner-back-button @click="$emit('back')" />
      <div class="banner-wrap">
        <question-banner @click="$emit('post')" />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionBanner from '@/views/components/coaching/question/QuestionBanner.vue';
import QuestionBannerBackButton from '@/views/components/coaching/question/QuestionBannerBackButton.vue';

export default {
  components: { QuestionBannerBackButton, QuestionBanner },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[question-banner-a] {.wh(100%, 337);.pt(92);.bgc(#5555e2);
  [question-banner-back-button] { .abs;.lt(36, -59); }
  > .top-content-wrap { .rel;.flex;.max-w(1280);.m(0, auto);.flex-jc(center); }
  > .top-content-wrap > .banner-wrap {.max-w(848);.wh(100%, fit-content);}
  @media (@ml-down) {
    .h(233);.pt(73);
  }
}
</style>
